import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { GW_URL,superAdminConfig } from "../config";

export const ALL_SCHOOL_FETCH_KEY = "school-list";

const fetchAllSchool = (superAdminconfig) => {
  const res = axios.get(`${GW_URL}/schools`,superAdminconfig );

  return res;
};

const useFetchAllSchool = (superAdminconfig) => {
  const { isLoading, isError, data } = useQuery(
    [ALL_SCHOOL_FETCH_KEY],
    () => fetchAllSchool(superAdminconfig),
    {
      staleTime: 6000000,
    }
  );
  return { isLoading, isError, data };
};

export default useFetchAllSchool;
