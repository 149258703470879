import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { GW_URL, adminConfig } from "../config";
export const ALL_TEACHER_CLASS_DETAILS_FETCH_KEY = "teacher-class-details-list";

const usefetchClassDetails = (teacher_id) => {
  const call = teacher_id === null;

  const { isLoading, isError, data, error } = useQuery(
    [ALL_TEACHER_CLASS_DETAILS_FETCH_KEY, teacher_id],
    () => {
      if (call) {
        return Promise.resolve(null);
      } else {
        return axios.get(
          `${GW_URL}/teacher/${teacher_id}/getTeacherMediumAndClass`,
          adminConfig
        );
      }
    },
    {
      enabled: !call,
    }
  );

  return { isLoading, isError, data, error };
};

export default usefetchClassDetails;
