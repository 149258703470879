import axios from "axios";
import { GW_URL, adminConfig } from "../config";
import { useEffect, useState } from "react";

const useGetStudentAllDetails = (student_id) => {
  const [studentDetails, setStudentDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getStudentDetails = () => {
    axios
      .get(`${GW_URL}/students/${student_id}/studentDetails`,adminConfig)
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        setStudentDetails(res.data.studentDetails)
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  useEffect(() => {getStudentDetails()}, [student_id]);

  return {
    studentDetails,
    isLoading,
  };
};

export default useGetStudentAllDetails;
