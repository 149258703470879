import { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import jwt_decode from "jwt-decode";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import "./ClassTeacher.scss";
import useFetchTeacher from "../../hooks/useFetchTeacher";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  GW_URL,
  adminConfig,
  allStreams,
  allSections,
  getAdminDetails,
} from "../../config";
import Loader from "../../components/Loader/Loader";
import DataGridWithTooltip from "../../components/DataGrid/DataGridWithToolTrip";
import useFetchAllClassTeacher, {
  ALL_CLASS_TEACHER,
} from "../../hooks/useFetchAllClassTeacher";
import useMediumQuery from "../../hooks/useGetMediuIds";
import SelectField from "../../components/selectTextField/selectTextfield";
import { classMapping, mediumMapping, sectionMapping } from "./helperfn";
import usefetchClassDetails from "../../hooks/useFetchTeacherClassDetails";

const columns = [
  {
    field: "id",
    headerName: "S No.",
    width: 50,
    flex: 1,
    editable: false,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "class_id",
    headerName: "Class Id",
    width: 50,
    hide: true,
    flex: 1,
    editable: false,
    headerAlign: "left",
    align: "left",
    renderCell: (params) => {
   
     
      return (
           <div>
           {
             classes.find((item)=>item.class_id===params.row.class_id).class_name
           }
           </div>
         );
       },
  },
  {
    field: "teacher_id",
    headerName: "Teacher Id",
    width: 50,
    hide: true,
    flex: 1,
    editable: false,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "teacher_name",
    flex: 1,
    headerName: "Teacher Name",
    width: 200,
    editable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "class_name",
    headerName: "Class",
    width: 50,
    flex: 1,
    editable: false,
    headerAlign: "center",
    align: "center",
  },
  
  {
    field: "medium",
    headerName: "Medium",
    width: 50,
    flex: 1,
    editable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "section",
    headerName: "Section",
    width: 50,
    flex: 1,
    editable: false,
    headerAlign: "center",
    align: "center",
  },
];

// const addClassTeacher = ({
//   school_id,
//   medium,
//   class_id,
//   teacher_id,
//   section,
//   stream,
// }) => {
//   if (stream.length == 0) {
//     return axios.put(
//       `${GW_URL}/schools/${school_id}/addClassTeacher`,
//       {
//         class_id,
//         teacher_id,
//         section,
//         medium,
//       },
//       adminConfig
//     );
//   } else {
//     return axios.put(
//       `${GW_URL}/schools/${school_id}/addClassTeacher`,
//       {
//         class_id,
//         teacher_id,
//         section,
//         medium,
//         stream,
//       },
//       adminConfig
//     );
//   }
// };

// const ClassTeacher = (props) => {
//   const [rows, setRows] = useState([]);
//   const [openLoader, setOpenLoader] = useState(false);
//   const [formData, setFormData] = useState({
//     teacherId: "",
//     classId: "",
//     section: "",
//     medium: "",
//     stream: "",
//   });
//   const [allClasses, setAllClasses] = useState([]);
//   const [teacherClassAndMediumInfo, setTeacherClassAndMediumInfo] = useState(
//     []
//   );
//   const [allMedium, setAllMedium] = useState([]);
//   const [allSection, setAllSection] = useState([]);
//   const [streamOpen, setStreamOpen] = useState(false);

//   let decodeToken = jwt_decode(localStorage.getItem("auth_token"));
//   let school_id =
//     localStorage.getItem("superadmin_school") === null
//       ? decodeToken.result.school_id
//       : localStorage.getItem("superadmin_school");
//   const { data: allTeacher } = useFetchTeacher(school_id);
//   const { isLoading, data: classTeacherData } =
//     useFetchAllClassTeacher(school_id);

//   const queryClient = useQueryClient();
//   const mutation = useMutation({
//     mutationFn: addClassTeacher,
//     onSuccess: () => {
//       setOpenLoader(false);
//       queryClient.invalidateQueries(ALL_CLASS_TEACHER);

//       toast.success("Class Teacher assigned successfully", {
//         position: "top-center",
//         autoClose: 1000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "dark",
//       });
//       setFormData({
//         teacherId: "",
//         classId: "",
//         section: "",
//         medium: "",
//         stream: "",
//       });
//       setStreamOpen(false);
//     },
//     onError: () => {
//       setOpenLoader(false);
//       toast.error("Something went wrong!", {
//         theme: "dark",
//       });
//     },
//   });

//   useEffect(() => {
//     if (!isLoading) {
//       let allClassTeacher = [];
//       for (let i = 0; i < classTeacherData?.data?.allClassTeacher.length; i++) {
//         let stream = classTeacherData?.data?.allClassTeacher[i].stream;
//         if (stream?.length == 0) {
//           stream = "---";
//         }
//         allClassTeacher.push({
//           ...classTeacherData?.data?.allClassTeacher[i],
//           stream: stream,
//           id: i + 1,
//         });
//       }
//       setRows(allClassTeacher);
//     }
//   }, [classTeacherData]);

//   useEffect(() => {
//     if (formData.teacherId != "") {
//       setAllClasses([]);
//       setAllSection([]);
//       setAllMedium([]);
//       setFormData((prev) => ({
//         ...prev,
//         medium: "",
//         section: "",
//         classId: "",
//       }));
//       axios
//         .get(
//           `${GW_URL}/teacher/${formData.teacherId}/getTeacherMediumAndClass`,
//           adminConfig
//         )
//         .then((data) => {
//           let teacherAllMediumAndClassInfo = data.data.allMediumAndClass;
//           let classes = [];
//           for (let i = 0; i < teacherAllMediumAndClassInfo?.length; i++) {
//             classes.push({
//               class_id: teacherAllMediumAndClassInfo[i].class_id,
//               class_name: teacherAllMediumAndClassInfo[i].class_name,
//             });
//           }
//           setAllClasses(classes);
//           setTeacherClassAndMediumInfo(teacherAllMediumAndClassInfo);
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     }
//   }, [formData.teacherId]);

//   useEffect(() => {
//     function upStream() {
//       if (formData.classId > 10) {
//         setStreamOpen(true);
//       } else {
//         setStreamOpen(false);
//       }
//     }
//     upStream();
//   }, [formData.classId]);

//   const ClassTeacherHandler = (e) => {
//     e.preventDefault();
//     if (formData.teacherId.length == 0) {
//       toast.error("Teacher is required!", {
//         theme: "dark",
//       });
//       return;
//     }
//     if (formData.classId.length == 0) {
//       toast.error("Class is required!", {
//         theme: "dark",
//       });
//       return;
//     }
//     if (parseInt(formData.classId) > 10 && formData.stream.length == 0) {
//       toast.error("Stream is required!", {
//         theme: "dark",
//       });
//       return;
//     }
//     if (formData.medium.length == 0) {
//       toast.error("Medium is required!", {
//         theme: "dark",
//       });
//       return;
//     }
//     if (formData.section.length == 0) {
//       toast.error("Section is required!", {
//         theme: "dark",
//       });
//       return;
//     }

//     setOpenLoader(true);
//     mutation.mutate({
//       school_id,
//       class_id: formData.classId,
//       medium: formData.medium,
//       teacher_id: formData.teacherId,
//       section: formData.section[0],
//       stream: formData.stream,
//     });
//   };

//   const handleTeacherChange = (e) => {
//     e.preventDefault();
//     let value = e.target.value;
//     setFormData((prev) => ({ ...prev, teacherId: value }));
//   };

//   const handleClassChange = (e) => {
//     e.preventDefault();
//     let value = e.target.value;
//     setFormData((prev) => ({ ...prev, medium: "", section: "", stream: "" }));
//     setAllSection([]);
//     setAllMedium(
//       teacherClassAndMediumInfo.filter((info) => info.class_id == value)[0]
//         .medium_section
//     );
//     setFormData((prev) => ({ ...prev, classId: value }));
//   };

//   const handleStreamChange = (e) => {
//     e.preventDefault();
//     let value = e.target.value;
//     setFormData((prev) => ({ ...prev, medium: "", section: "" }));
//     setAllSection([]);
//     setFormData((prev) => ({ ...prev, stream: value }));
//   };

//   const handleMediumChange = (e, value) => {
//     e.preventDefault();
//     for (let i = 0; i < teacherClassAndMediumInfo.length; i++) {
//       if (teacherClassAndMediumInfo[i].class_id == formData.classId) {
//         allMedium.map((item, index) => {
//           let mediumArray = Object.keys(item);
//           for (let j = 0; j < mediumArray.length; j++) {
//             if (mediumArray[j] == value) {
//               setAllSection(Object.values(item)[j].map((ele) => ele));
//             }
//           }
//         });
//       }
//     }

//     setFormData((prev) => ({ ...prev, medium: value, section: "" }));
//   };

//   const handleSectionChange = (e) => {
//     e.preventDefault();
//     let value = e.target.value;
//     setFormData((prev) => ({ ...prev, section: value }));
//   };

//   return (
//     <div className="classTeacher-container">
//       <Sidebar />
//       <div className="classTeachers">
//         <Navbar adminName={props.AdminName} />
//         <div className="classTeacher-page page-container">
//           <div className="classTeacher-page-container">
//             <div className="classTeacher-page-container-heading">
//               <span>Add Class Teacher</span>
//             </div>
//             <form noValidate onSubmit={ClassTeacherHandler}>
//               <div className="classTeacher-info-detail-container">
//                 <div className="classTeacher-info-detail-student-container">
//                   <div className="classTeacher-info-detail-student-container-subheading">
//                     <span>Teacher Details</span>
//                   </div>
//                   <div className="classTeacher-info-detail-student-container-textfield">
//                     <div className="classTeacher-info-section ">
//                       <TextField
//                         name="teacher"
//                         label="Teacher"
//                         select
//                         value={formData.teacherId}
//                         onChange={handleTeacherChange}
//                         fullWidth
//                         margin="normal"
//                         SelectProps={{
//                           multiple: false,
//                         }}
//                       >
//                         {allTeacher?.data?.teacherList?.map((option) => (
//                           <MenuItem key={option.teacher_id} value={option.teacher_id}>
//                             {option.teacher_name}
//                           </MenuItem>
//                         ))}
//                       </TextField>
//                       <TextField
//                         name="class"
//                         label="Class"
//                         select
//                         value={formData.classId}
//                         onChange={handleClassChange}
//                         fullWidth
//                         margin="normal"
//                         SelectProps={{
//                           multiple: false,
//                         }}
//                       >
//                         {allClasses?.map((option) => (
//                           <MenuItem
//                             key={option.class_id}
//                             value={option.class_id}
//                           >
//                             {option.class_name}
//                           </MenuItem>
//                         ))}
//                       </TextField>

//                       {streamOpen && (
//                         <TextField
//                           name="stream"
//                           label="Stream"
//                           select
//                           value={formData.stream}
//                           onChange={handleStreamChange}
//                           fullWidth
//                           margin="normal"
//                           SelectProps={{
//                             multiple: false,
//                           }}
//                         >
//                           {allStreams?.map((option) => (
//                             <MenuItem key={option.value} value={option.value}>
//                               {option.label}
//                             </MenuItem>
//                           ))}
//                         </TextField>
//                       )}

//                       <FormControl
//                         fullWidth
//                         margin="normal"
//                         style={{ flexGrow: 1 }}
//                       >
//                         <InputLabel id={`medium-label`}>Medium</InputLabel>
//                         <Select
//                           labelId={`medium-label`}
//                           name={`medium`}
//                           value={formData.medium}
//                           onChange={(e) =>
//                             handleMediumChange(e, e.target.value)
//                           }
//                           required
//                         >
//                           {allMedium.map((item, index) =>
//                             Object.keys(item).map((ele) => (
//                               <MenuItem key={index} value={ele}>
//                                 {ele}
//                               </MenuItem>
//                             ))
//                           )}
//                         </Select>
//                       </FormControl>

//                       <TextField
//                         name="section"
//                         label="Section"
//                         select
//                         value={formData.section}
//                         onChange={handleSectionChange}
//                         fullWidth
//                         margin="normal"
//                         SelectProps={{
//                           multiple: false,
//                         }}
//                       >
//                         {allSection?.map((option) => (
//                           <MenuItem key={option} value={option}>
//                             {option}
//                           </MenuItem>
//                         ))}
//                       </TextField>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="buttonSubmit">
//                   {" "}
//                   <button>Update</button>{" "}
//                 </div>
//               </div>
//             </form>
//           </div>
//           <DataGridWithTooltip
//             rows={rows}
//             columns={columns}
//             loader={isLoading}
//             emptyRowsMessage={"No Teacher Assigned"}
//           />
//         </div>
//       </div>
//       <ToastContainer />
//       <Loader open={openLoader} />
//     </div>
//   );
// };

// export default ClassTeacher;
const class_medium_section = [
  {
    class_id: -3,
    medium: "English",
    sections: "A",
  },
  {
    class_id: -3,
    medium: "Hindi",
    sections: "A",
  },
  {
    class_id: 11,
    medium: "English",
    sections: "A",
  },
  {
    class_id: 12,
    medium: "English",
    sections: "B",
  },
];

const ClassTeacher = (props) => {
  const { school_id } = getAdminDetails();
  const {
    data: allTeacher,
    isLoading: teacherLoading,
    isError: teacherError,
  } = useFetchTeacher(school_id);
  const [teacherId, setTeacherId] = useState(null);
  const [teacherData, setTeacherData] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [selectedMedium, setSelectedMedium] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [classOptions, setClassOptions] = useState([]);
  const [mediumOptions, setMediumOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [stream, setStream] = useState();
  const [streamOpen, setStreamOPen] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [loading,setLoading]=useState(false);
  const [allClassTeacher,setAllClassTeacher]=useState([]);

  const { isLoading, data } = usefetchClassDetails(teacherId);
  //   const { isLoading:classTeacherLoading, data: classTeacherData } =
  //   useFetchAllClassTeacher(school_id);

  //     useEffect(() => {
  //   if (!isLoading) {
  //     console.log(data.data.allClassTeacher)
  //     let allClassTeacher = [];
  //     for (let i = 0; i < classTeacherData?.data?.allClassTeacher.length; i++) {
  //       let stream = classTeacherData?.data?.allClassTeacher[i].stream;
  //       if (stream?.length == 0) {
  //         stream = "---";
  //       }
  //       allClassTeacher.push({
  //         ...classTeacherData?.data?.allClassTeacher[i],
  //         stream: stream,
  //         id: i + 1,
  //       });
  //     }
  //     setAllClassTeacher(allClassTeacher);
  //   }
  // }, [classTeacherData]);
  const getAllClassTeacher=()=>{
   
    axios.get(`${GW_URL}/schools/${school_id}/getAllClassTeacher`).then((res)=>{
      
        let allTeacher=res.data.allClassTeacher;
        let teachers=[];
        for(let i=0;i<allTeacher.length;i++)
        {
           allTeacher[i].id=i+1;
           teachers.push(allTeacher[i]);
        }

        setAllClassTeacher(teachers);
    }).catch((err)=>{
      console.log(err);
    })
  }

  useEffect(() => {``
    if (!isLoading) {
      setTeacherData(data.data.class_medium_section_subject_list);
    }
  }, [data]);

  // Function to handle teacher selection
  const handleTeacherSelect = (selectedTeacherId) => {
    setTeacherId(selectedTeacherId);
    setSelectedClassId(null);
    setSelectedMedium("");
    setSelectedSection("");
  };

  // Function to handle class selection
  const handleClassSelect = (selectedClassId) => {
    setSelectedClassId(selectedClassId);
    setSelectedMedium("");
    setSelectedSection("");
    if (selectedClassId > 10) {
      setStreamOPen(true);
    } else setStreamOPen(false);
  };

  // Function to handle medium selection
  const handleMediumSelect = (selectedMedium) => {
    setSelectedMedium(selectedMedium);
    setSelectedSection("");
  };

  // Function to handle section selection
  const handleSectionSelect = (selectedSection) => {
    setSelectedSection(selectedSection);
  };
  const handleStreamSelect=(selectedStream)=>{
    console.log(selectedStream);
    setStream(selectedStream)
  }

  // useEffect to update class options when teacherId changes
  useEffect(() => {
    // Filter class options based on teacherId and update state
    if (teacherId) {
      let arr = [];

      teacherData.forEach((item) => {
        arr.push(item.class_id);
      });
      let uniqueSet = new Set(arr);
      arr = Array.from(uniqueSet);
      arr = classMapping(arr);

      setClassOptions(arr);
    }
  }, [teacherId, teacherData]);

  // useEffect to update medium options when selectedClassId changes
  useEffect(() => {
    if (selectedClassId) {
      // Filter medium options based on selectedClassId and update state
      let filteredMediums = teacherData
        .filter((item) => item.class_id === selectedClassId)
        .map((item) => item.medium);
      let uniueSet = new Set(filteredMediums);
      filteredMediums = Array.from(uniueSet);
      filteredMediums = mediumMapping(filteredMediums);
      setMediumOptions(filteredMediums);
    }
  }, [selectedClassId, teacherData]);

  // useEffect to update section options when selectedMedium changes
  useEffect(() => {
    if (selectedMedium) {
      // Filter section options based on selectedClassId, selectedMedium, and update state
      let filteredSections = teacherData
        .filter(
          (item) =>
            item.class_id === selectedClassId && item.medium === selectedMedium
        )
        .map((item) => item.sections);
      let uniqueSet = new Set(filteredSections);
      filteredSections = Array.from(uniqueSet);
      filteredSections = sectionMapping(filteredSections);
      setSectionOptions(filteredSections);
    }
  }, [selectedMedium, selectedClassId, teacherData]);

  useEffect(()=>{
    getAllClassTeacher();

  },[])

  const addClassTeacher=(e)=>{
    e.preventDefault();
    
    if(teacherId===null)
    {
      toast.error("Select A teacher");
      return ;
    }
    if(selectedClassId===null)
    {
      toast.error("Select a Class");
      return ;
    }
    // if(streamOpen && stream==="")
    // {
    //   toast.error("Select a Stream");
    //   return ;
    // }
    if(selectedMedium==="")
    {
      toast.error("Select a Medium");
      return ;
    }
    if(selectedSection==="")
    {
      toast.error("Select a Section");
      return ;
    }
    setLoading(true);
  
    axios.put(`${GW_URL}/schools/${school_id}/addClassTeacher`,
      {
        "class_id":selectedClassId,
        "medium":selectedMedium,
        "section":selectedSection,
        "teacher_id":teacherId,
        "stream":stream
    }
    ).then((res)=>{
      toast.success(res.data.message)
      setLoading(false);
      getAllClassTeacher();
      // setTeacherData("");
      // setSelectedClassId("");
      // setSelectedMedium("");
      // setSelectedMedium
    
    }).catch(err =>{
      toast.error("Some went wrong please try again")
      setLoading(false);
    })
  }

  return (
    <div className="classTeacher-container">
      <Sidebar />
      <div className="classTeachers">
        <Navbar adminName={props.AdminName} />
        <div className="classTeacher-page page-container">
          <div className="classTeacher-page-container">
            <div className="classTeacher-page-container-heading">
              <span>Add Class Teacher</span>
            </div>
            <form noValidate>
              <div className="classTeacher-info-detail-container">
                <div className="classTeacher-info-detail-student-container">
                  <div className="classTeacher-info-detail-student-container-subheading">
                    <span>Teacher Details</span>
                  </div>
                  <div className="classTeacher-info-detail-student-container-textfield">
                    <SelectField
                      label="Select Teacher"
                      items={allTeacher?.data?.teacherList}
                      onChange={handleTeacherSelect}
                      idKey="teacher_id"
                      nameKey="teacher_name"
                      dfValue={teacherId}
                      loading={teacherLoading}
                    />
                    {/* Dropdown for class selection */}
                    <SelectField
                      label="Select Class"
                      items={classOptions}
                      onChange={handleClassSelect}
                      idKey="class_id"
                      nameKey="class_name"
                      dfValue={selectedClassId}
                      loading={!teacherId} // Disable class selection if teacher is not selected
                    />
                    {/* {streamOpen && (
                      <SelectField
                        label="Select Stream"
                        items={allStreams}
                        onChange={handleStreamSelect}
                        idKey={"value"}
                        nameKey={"label"}
                        dfValue={stream}
                        loading={!selectedClassId} // Disable section selection if medium is not selected
                      />
                    )} */}
                    {/* Dropdown for medium selection */}
                    <SelectField
                      label="Select Medium"
                      items={mediumOptions}
                      onChange={handleMediumSelect}
                      idKey={"medium_name"}
                      nameKey={"medium_name"}
                      dfValue={selectedMedium}
                      loading={!selectedClassId} // Disable medium selection if class is not selected
                    />
                    {/* Dropdown for section selection */}
                    <SelectField
                      label="Select Section"
                      items={sectionOptions}
                      onChange={handleSectionSelect}
                      idKey={"section_name"}
                      nameKey={"section_name"}
                      dfValue={selectedSection}
                      loading={!selectedMedium} // Disable section selection if medium is not selected
                    />
                  </div>
                </div>
                <div className="buttonSubmit">
                  {" "}
                  <button onClick={addClassTeacher}>Update</button>{" "}
                </div>
              </div>
            </form>
          </div>
          <DataGridWithTooltip
            rows={allClassTeacher}
            columns={columns}
            loader={isLoading}
            emptyRowsMessage={"No Teacher Assigned"}
          />
        </div>
      </div>
      <ToastContainer />
      <Loader open={loading} />
    </div>
  );
};

export default ClassTeacher;
