import { subjects,classes,medium } from "../../config";
export const subjectMapping = (subject_ids) => {
    const mappedData = subject_ids.map((subjectId) => {
      const subject = subjects.find((s) => s.subject_id === subjectId);
      return {
        subject_id: subjectId,
        subject_name: subject ? subject.subject_name : "Unknown",
      };
    });
    return mappedData;
  };
  export const classMapping = (class_ids) => {
    if (class_ids === null) return [];
    const mappedData = class_ids.map((classId) => {
      const classs = classes.find((c) => c.class_id === classId);
      return {
        class_id: classId,
        class_name: classs ? classs.class_name : "Unknown",
      };
    });
  
    return mappedData;
  };
  export const mediumMapping = (medium_ids) => {
  
    const md_list = [];
    for (let md of medium_ids) {
      let d;
      if (md ==='English') {
        d = {
          medium_id: 1,
          medium_name: "English",
        };
      } else {
        d = {
          medium_id: 1,
          medium_name: "Hindi",
        };
      }
      md_list.push(d);
    }
    return md_list;
  };
  export const sectionMapping=(section_names)=>{
    const section_list=[];
    for(let sc of section_names)
    {
       let  d={
            section_name:sc
         }
        section_list.push(d);
    }
    return section_list;
  }