import { create } from "zustand";
import axios from "axios";
import { GW_URL,superAdminConfig } from "../config";
const useStudentTotal = create((set) => ({
  amount: {},
  isLoading: false,
  getFetch: (school_id, student_id) => {
   
    set({ isLoading: true });
    axios
      .get(`${GW_URL}/schools/${school_id}/${student_id}/getEssentialAmount`, superAdminConfig)
      .then((res) => {
        const list = res.data.essential_lists[0];
       
        const { books_amount, uniform_amount, vehicle_amount } = list;
        set((state)=>({...state,amount:list}));
        set({ isLoading: false });
      })
      .catch((err) => {
        console.log(err);
        set({ isLoading: false });
      });
  },
}));

export default useStudentTotal;
