import React from 'react';
import TextField from '@mui/material/TextField';

const CustomTextField = ({ value, error, label, type, helperText, onChange, ...otherProps }) => {
  return (
    <TextField
      value={value}
      error={error}
      label={label}
      type={type}
      helperText={helperText}
      fullWidth
      onChange={onChange}
      {...otherProps}
    />
  );
};

export default CustomTextField;
