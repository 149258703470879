import React, { useEffect } from "react";
import Essentialform from "./Essentialform/Essentialform";
// import "./CreateEssentail.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import DetailsTable from "./DetailsTable/DetailsTable";
import Payment from "./Payments/Payment";
import useStudentEssential from "../../zustand/useStudentEssectial";
import TransactionTable from "./TransactionTable/TransactionTable";

const CreateEssential = ({ student_id, school_id }) => {
  return (
    <>
      <Essentialform student_id={student_id} school_id={school_id} />
      <DetailsTable  student_id={student_id} school_id={school_id}/>
      <Payment student_id={student_id} school_id={school_id} />
      <TransactionTable student_id={student_id} school_id={school_id}/>
    </>
  );
};

export default CreateEssential;
