import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { GW_URL, superAdminConfig } from "../../../config";
import axios from "axios";
import Loader from "../../../components/Loader/Loader";
import useStudentEssential from "../../../zustand/useStudentEssectial";
import { toast, ToastContainer } from "react-toastify";


const Payment = ({ student_id, school_id }) => {
  const [amount, setAmount] = useState("");
  const [category, setCategory] = useState("");
  const [mode, setMode] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { getEssential } = useStudentEssential((state) => state);

  const updateInfo = () => {

    if (amount ==="") {
      toast.warn("Enter amount", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      return;
    }
    if (category ==="") {
      toast.warn("Enter Caterogy Type", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      return;
    }
    if (mode ==="") {
      toast.warn("Enter Payment mode", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      return;
    }
    if (transactionId ==="") {
      toast.warn("Enter Transaction Id", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      return;
    }
    setIsLoading(true);
    axios
      .post(
        `${GW_URL}/schools/${school_id}/${student_id}/addStudentEssentialTransaction`,
        {
          category,
          amount,
          transaction_id: transactionId,
          mode,
        },
        superAdminConfig
      )
      .then((res) => {
        setIsLoading(false);
        setAmount("");
        setCategory("");
        setMode("");
        setTransactionId("");
        toast.success("Transaction update Successfull", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        getEssential(school_id, student_id);
      })
      .catch((err) => {
        toast.error("Someting went wrong", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setIsLoading(false);
      });
  };

  return (
    <div className="essential_container">
      <div>
        <span>Transaction Update</span>
      </div>
      <div style={{ display: "flex", columnGap: "1rem" }}>
        <TextField
          value={amount}
          label="Amount"
          required
          fullWidth
          onChange={(e) => setAmount(e.target.value)}
        />
        <TextField
          select
          value={category}
          label="Category"
          required
          fullWidth
          onChange={(e) => setCategory(e.target.value)}
        >
          <MenuItem value="books">Books</MenuItem>
          <MenuItem value="vehicle">Vehicle</MenuItem>
          <MenuItem value="uniform">Uniform</MenuItem>
        </TextField>
        <TextField
          select
          value={mode}
          label="Mode"
          required
          fullWidth
          onChange={(e) => setMode(e.target.value)}
        >
          <MenuItem value="Cash">Cash</MenuItem>
          <MenuItem value="Online">Online</MenuItem>
        </TextField>

        <TextField
          value={transactionId}
          label="Transaction ID"
          required
          fullWidth
          onChange={(e) => setTransactionId(e.target.value)}
        />
        <Loader open={isLoading} />
      </div>
        <button onClick={updateInfo}>Update</button>
    </div>
  );
};

export default Payment;
