import React from 'react'
import DataGridWithTooltip from "../../../components/DataGrid/DataGridWithToolTrip";
import useStudentEssential from '../../../zustand/useStudentEssectial';
import { capitalizeFirstLetter } from '../../../config';

function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}
const TransactionColumn = [
    {
      field: "id",
      headerName: "S No.",
      width: 150,
      flex: 1,
      headerAlign: "left",
      align: "left",
      hide: true,
    },
    {
      field: "transaction_date",
      headerName: "Date",
    //   hide: true,
      width: 150,
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
         <span>{formatDate(params.row.transaction_date)}</span>
      ),
    
    },
    {
      field: "transaction_type",
      headerName: "Category",
      hide: false,
      width: 150,
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <span>{capitalizeFirstLetter(params.row.transaction_type)}</span>
     ),
    },
    {
      field: "amount",
      flex: 1,
      headerName: "Amount",
      width: 150,
      editable: false,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "payment_mode",
      headerName: "Mode",
      type: "number",
      width: 150,
      flex: 1,
      editable: false,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "transaction_id",
      headerName: "Trasaction Id",
      type: "number",
      width: 150,
      flex: 1,
      editable: false,
      headerAlign: "left",
      align: "left",
    },
   
  ];

const TransactionTable = () => {
    const {essentialList,is_loading}=useStudentEssential((state)=>state);
    console.log(essentialList);
  return (
    <>
    {
      is_loading ?  <span>Loading ..</span> :
      <DataGridWithTooltip rows={essentialList} columns={TransactionColumn}/>
    }
    </>
  )
}

export default TransactionTable