import React, { useEffect ,useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useStudentEssential from "../../../zustand/useStudentEssectial";
import useStudentTotal from "../../../zustand/useStudentTotal";
import axios from "axios";
import { GW_URL,superAdminConfig } from "../../../config";

const DetailsTable = ({ student_id, school_id }) => {
  const { essentialList, is_loading, getEssential ,eachTypeAmount} = useStudentEssential();
  const {amount,isLoading,getFetch}=useStudentTotal();
 


  useEffect(() => {
    getEssential(school_id, student_id);
  getFetch(school_id,student_id)
  }, []);

  return (
    <div className="essential_container">
      <Typography variant="h6" gutterBottom>
        Student Essential Amount Details
      </Typography>
      {is_loading || isLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="200px">
          <CircularProgress />
        </Box>
      ) : (
        <div className="essential_details">
          <div className="essential_item">
            <Typography variant="subtitle1" fontWeight="bold">Books :</Typography>
            {
              amount?.books_amount ? 
              <Typography variant="subtitle1">{eachTypeAmount?.books || 0}/{amount?.books_amount}</Typography>:
              <Typography variant="subtitle1" >Not yet Added </Typography>

            }
          </div>
          <div className="essential_item">
            <Typography variant="subtitle1" fontWeight="bold">Vehicle : </Typography>
            {
              amount?.books_amount ? 
              <Typography variant="subtitle1">{eachTypeAmount?.vehicle || 0}/{amount?.vehicle_amount}</Typography>:
              <Typography variant="subtitle1" >Not yet Added </Typography>

            }
          </div>
          <div className="essential_item">
            <Typography variant="subtitle1" fontWeight="bold">Uniform : </Typography>
            {
              amount?.books_amount ? 
              <Typography variant="subtitle1">{eachTypeAmount?.uniform || 0}/{amount?.uniform_amount}</Typography>:
              <Typography variant="subtitle1" >Not yet Added </Typography>

            }
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailsTable;
