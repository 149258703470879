import React, { useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  CircularProgress,
  Chip,
  Typography,
} from "@mui/material";
import useGetClassIdSubjects from "../../hooks/useGetClassIdSubject";
// Import the Delete icon

const MultiSelectField = ({
  label,
  items = [],
  loading,
  onChange,
  idKey = "id",
  nameKey = "name",
  initialSelectedItems = [],
  selectedItems,
  setSelectedItems,
}) => {
  
  useEffect(() => {
    if (initialSelectedItems && initialSelectedItems.length > 0) {
      setSelectedItems(initialSelectedItems?.map((item) => item[idKey]) || []);
    }
  }, [initialSelectedItems, idKey, items]);


  const handleChange = (event) => {
    const selectedValues = event.target.value;
    setSelectedItems(selectedValues);
    if (onChange) {
      onChange(selectedValues);
    }
  };

  return (
    <FormControl fullWidth sx={{ height: "auto" }}>
      <InputLabel id="multi-select-field-label">{label}</InputLabel>

      <Select
        sx={{
          height: "auto",
        }}
        labelId="multi-select-field-label"
        id="multi-select-field"
        multiple
        rows={3}
        multiline={true}
        value={selectedItems || []}
        onChange={handleChange}
        disabled={loading}
        // renderValue={(selected) => (
        //   <div>
        //     {selected?.map((value) => (
        //       <Chip
        //         key={value}
        //         label={items?.find((item) => item[idKey] === value)[nameKey]}
        //         onDelete={() => handleDeleteChip(value)}
        //         style={{ marginRight: 4, marginBottom: 4 }}
        //       />
        //     ))}
        //   </div>
        // )}
        fullWidth
      >
        {loading ? (
          <MenuItem value="">
            <CircularProgress size={24} />
          </MenuItem>
        ) : (
          items?.map((item) => (
            <MenuItem key={item[idKey]} value={item[idKey]}>
              <Checkbox checked={selectedItems?.includes(item[idKey])} />
              {item[nameKey]}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
};

export default MultiSelectField;
