import { create } from "zustand";
import axios from "axios";
import { GW_URL, superAdminConfig } from "../config";

function calculateTotalAmounts(transactions) {
  return transactions.reduce((totalAmounts, transaction) => {
      totalAmounts[transaction.transaction_type] = (totalAmounts[transaction.transaction_type] || 0) + transaction.amount;
      return totalAmounts;
  }, {});
}

const useStudentEssential = create((set) => ({
  essentialList: [],
  eachTypeAmount:{},
  is_loading: false,
  is_error: false,
  error: null,
  getEssential: async (school_id, student_id) => {
  
    set((state) => ({ is_loading: false }));

    try {
      const res = await axios.get(
        `${GW_URL}/schools/${school_id}/${student_id}/getStudentEssential`,
        superAdminConfig
      );
      let list = res.data.essential_list;
      let list1=calculateTotalAmounts(list);
      set((state)=>({...state,eachTypeAmount:list1}));
    
      
      for (let i = 0; i < list.length; i++) {
        list[i].id = i + 1;
      }
      set((state) => ({ ...state, essentialList: list }));
      set((state) => ({ is_error: false }));
    } catch (err) {
      set((state) => ({ error: err }));
    }
  },
}));

export default useStudentEssential;
