import React, { useEffect, useState } from "react";
import useGetStudentAllDetails from "../../hooks/useGetStudentDetails2";
import { DatePicker } from "@mui/x-date-pickers";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CustomTextField from "../TextField/TextFiels";
import dayjs from "dayjs";
import { FaShekelSign } from "react-icons/fa";
import { genderArray, allSections, getAdminDetails,allStreams } from "../../config";
import SelectField from "../selectTextField/selectTextfield";
import useMediumQuery from "../../hooks/useGetMediuIds";
import useClassIdsQuery from "../../hooks/useGetSchoolIds";
import useBoardIdQuery from "../../hooks/useGetBoard";

const EditStudent = ({ student_id, onClose ,onSubmit}) => {
  const { school_id } = getAdminDetails();

 
  const { isLoading, studentDetails } = useGetStudentAllDetails(student_id);
  const [pastParentNumber,setPastParentNumber]=useState("");
  const [streamOpen,setStreamOpen]=useState(false);
  
  const { data: mediumIdData, isLoading: mediumIdLoading } =
    useMediumQuery(school_id);

  const { data: classIdData, isLoading: classIdLoading } =
    useClassIdsQuery(school_id);

  const { data: boardIdData, isLoading: boardIdLoading } =
    useBoardIdQuery(school_id);

  const [studentData, setStudentData] = useState({
    student_name: "",
    class_id: "",
    medium: "",
    board: "",
    gender: "",
    dob: "",
    address: "",
    section: "",
    father_name: "",
    mother_name: "",
    father_profession: "",
    mother_profession: "",
    whatsapp_no: "",
    alternate_mobile: "",
    email: "",
    first_installment: "",
    first_installment_eta: null,
    second_installment: "",
    second_installment_eta: null,
    third_installment: "",
    third_installment_eta: null,
    stream:""
  });
  const [studentError, setStudentError] = useState({
    student_name: false,
    class_id: false,
    medium: false,
    board: false,
    gender: false,
    dob: false,
    address: false,
    section: false,
    father_name: false,
    mother_name: false,
    father_profession: false,
    mother_profession: false,
    whatsapp_no: false,
    alternate_mobile: false,
    email: false,
    first_installment: false,
    first_installment_eta: false,
    second_installment: false,
    second_installment_eta: false,
    third_installment: false,
    third_installment_eta: false,
    stream:false
  });
  const handleFieldChange = (fieldName, value) => {
    console.log(fieldName,value);
   
    setStudentData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
  
    if (!isLoading) {
      console.log(studentDetails[0]);
      setStudentData({
        student_name: studentDetails[0].student_name,
        class_id: studentDetails[0].class_id,
        medium: studentDetails[0].medium,
        board: studentDetails[0].board,
        gender: studentDetails[0].gender,
        address: studentDetails[0].address,
        section: studentDetails[0].section,
        dob: studentDetails[0].dob,
        father_name: studentDetails[0].father_name,
        mother_name: studentDetails[0].mother_name,
        father_profession: studentDetails[0].father_profession,
        mother_profession: studentDetails[0].mother_profession,
        whatsapp_no: studentDetails[0].whatsapp_no,
        alternate_mobile: studentDetails[0].alternate_mobile,
        email: studentDetails[0].email,
        first_installment: studentDetails[0].first_installment,
        second_installment: studentDetails[0].second_installment,
        third_installment: studentDetails[0].third_installment,
        first_installment_eta: studentDetails[0].first_installment_eta,
        second_installment_eta: studentDetails[0].second_installment_eta,
        third_installment_eta: studentDetails[0].third_installment_eta,
        parent_id:studentDetails[0].parent_id,
        stram:studentDetails[0].stream
      });
      setPastParentNumber(studentDetails[0].whatsapp_no);
    }
  }, [studentDetails]);

  useEffect(()=>{
    if(!isLoading)
    {
      
      if(studentData.class_id>10)
      {
        setStreamOpen(true);
        setStudentData((prev)=>({
          ...prev,
           stream:studentDetails[0].stream
        }));

      }else
      {
        setStreamOpen(false);
        setStudentData((prev)=>({
          ...prev,
           stream:studentDetails[0].stream
        }));
      }
    }
  },[studentData])
  // handler functions

 

  const handleSubmit = () => {
  onSubmit(studentData,pastParentNumber,streamOpen);
  };

  return (
    <div style={{ padding: "4rem" }}>
      <Dialog
        open
        onClose={onClose}
        minWidth="80vw"
        fullWidth={true}
        PaperProps={{
          style: {
            minWidth: "1400px",
            width: "70vw", // Set the desired width
            // Set the desired height
            height: "auto",
            backgroundColor: "#F9FCFE",
          },
        }}
      >
        <DialogTitle>Edit Student</DialogTitle>
        {isLoading ? (
          <span>Loading ..</span>
        ) : (
          <DialogContent fullWidth>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "1rem",
                padding: "2rem 1rem",
              }}
            >
              <CustomTextField
                value={studentData.student_name}
                error={studentError.student_name}
                label="Student name"
                onChange={(e) =>
                  handleFieldChange("student_name", e.target.value)
                }
              />
              <SelectField
                label="Class"
                items={classIdData}
                loading={classIdLoading}
                onChange={(e) => handleFieldChange("class_id", e)}
                idKey={"class_id"}
                nameKey={"class_name"}
                dfValue={studentData.class_id}
              />
              {
                streamOpen &&
              
               <SelectField
                label="Stream"
                items={allStreams}
                loading={classIdLoading}
                onChange={(e) => handleFieldChange("stream", e)}
                idKey={"value"}
                nameKey={"label"}
                dfValue={studentData.stream}
              />
}
              <SelectField
                label="Medium"
                items={mediumIdData}
                loading={mediumIdLoading}
                onChange={(e) => handleFieldChange("medium", e)}
                idKey={"medium_name"}
                nameKey={"medium_name"}
                dfValue={studentData.medium}
              />
              <SelectField
                label="Section"
                items={allSections}
                onChange={(e) => handleFieldChange("section", e)}
                idKey={"section_name"}
                nameKey={"section_name"}
                dfValue={studentData.section}
              />
              <SelectField
                label="Gender"
                items={genderArray}
                idKey="value"
                nameKey="label"
                dfValue={studentData.gender}
                onChange={(e) => handleFieldChange("gender", e)}
              />
              <CustomTextField
                value={studentData.address}
                error={studentError.address}
                label="Address"
                onChange={(e) =>
                  handleFieldChange("address", e.target.value)
                }
              />
              <CustomTextField
                value={studentData.father_name}
                error={studentError.father_name}
                label="Father name"
                onChange={(e) =>
                  handleFieldChange("father_name", e.target.value)
                }
              />
              <CustomTextField
                value={studentData.mother_name}
                error={studentError.mother_name}
                label="Mother Name"
                onChange={(e) =>
                  handleFieldChange("mother_name", e.target.value)
                }
              />
              <CustomTextField
                value={studentData.whatsapp_no}
                error={studentError.whatsapp_no}
                label="Primary Number"
                onChange={(e) =>
                  handleFieldChange("whatsapp_no", e.target.value)
                }
              />
              <CustomTextField
                value={studentData.alternate_mobile}
                error={studentError.alternate_mobile}
                label="Alternate Number"
                onChange={(e) =>
                  handleFieldChange("alternate_mobile", e.target.value)
                }
              />
              <CustomTextField
                value={studentData.email}
                error={studentError.email}
                label="Email"
                onChange={(e) => handleFieldChange("email", e.target.value)}
              />
               <CustomTextField
                value={studentData.father_profession}
                error={studentError.father_profession}
                label="Father Profession"
                onChange={(e) =>
                  handleFieldChange("father_profession", e.target.value)
                }
              />
              <CustomTextField
                value={studentData.mother_profession}
                error={studentError.mother_profession}
                label="Mother Profession"
                onChange={(e) =>
                  handleFieldChange("mother_profession", e.target.value)
                }
              />
              <CustomTextField
                value={studentData.first_installment}
                error={studentError.first_installment}
                label="First Installment Amount"
                onChange={(e) =>
                  handleFieldChange("first_installment", e.target.value)
                }
              />
              <DatePicker
                value={dayjs(studentData.first_installment_eta)}
                sx={{ flex: 1 }}
                error={studentError.first_installment_eta}
                format="DD/MM/YYYY"
                type="date"
                onChange={(e) =>
                  handleFieldChange("first_installment_eta", e)
                }
                required
              />
              <CustomTextField
                value={studentData.second_installment}
                error={studentError.second_installment}
                label="Second installment"
                onChange={(e) =>
                  handleFieldChange("second_installment", e.target.value)
                }
              />
              <DatePicker
                value={dayjs(studentData.first_installment_eta)}
                sx={{ flex: 1 }}
                error={studentError.first_installment_eta}
                format="DD/MM/YYYY"
                type="date"
                onChange={(e) =>
                  handleFieldChange("second_installment_eta", e)
                }
                required
              />
              <CustomTextField
                value={studentData.third_installment}
                error={studentError.third_installment}
                label="Third Installment Amount"
                onChange={(e) =>
                  handleFieldChange("third_installment", e.target.value)
                }
              />
              <DatePicker
                value={dayjs(studentData.third_installment_eta)}
                sx={{ flex: 1 }}
                error={studentError.third_installment_eta}
                format="DD/MM/YYYY"
                type="date"
                onChange={(e) =>
                  handleFieldChange("third_installment_eta", e)
                }
                required
              />
            </div>
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditStudent;
