import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { GW_URL, superAdminConfig } from "../../../config";
import { School } from "@mui/icons-material";
import useStudentEssential from "../../../zustand/useStudentEssectial";
import Loader from "../../../components/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import "../../StudentPage/Studentpage.scss";
import "../CreateEssential.scss";
import useStudentTotal from "../../../zustand/useStudentTotal";


const Essentialform = ({ school_id, student_id }) => {
  const [books, setBooks] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [uniform, setUniform] = useState("");
  const { getEssential } = useStudentEssential((state) => state);
  const {getFetch}=useStudentTotal((state)=>state);
  const [isLoading, setIsLoading] = useState(false);

  const addAmount = () => {
    // Function to handle addition

    if (books ==="") {
      toast.warn("Enter amount", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      return;
    }
    if (vehicle ==="") {
      toast.warn("Enter amount", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      return;
    }
    if (uniform ==="") {
      toast.warn("Enter amount", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      return;
    }

    setIsLoading(true);
    axios
      .post(
        `${GW_URL}/schools/${school_id}/${student_id}/addStudentEssential`,
        {
          uniform,
          books,
          vehicle,
        },
        superAdminConfig
      )
      .then((res) => {
        setIsLoading(false);
        setVehicle("");
        setBooks("");
        setUniform("");
        toast.success(res.data.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        getFetch(school_id,student_id);

       
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setIsLoading(false);
      });
  };

  return (
    <div className="essential_container">
    <div>
        <span>Student Essential Amount</span>
    </div>
    <div style={{display:'flex',columnGap:"1rem"}}>
      <TextField
        value={books}
        sx={{ flex: 1 }}
        label="Books"
        required
        helperText="Books"
        onChange={(e) => setBooks(e.target.value)}
      />
      <TextField
        value={vehicle}
        sx={{ flex: 1 }}
        label="Vehicle"
        required
        helperText="Vehicle"
        onChange={(e) => setVehicle(e.target.value)}
      />
      <TextField
        value={uniform}
        sx={{ flex: 1 }}
        label="Uniform"
        required
        helperText="Uniform"
        onChange={(e) => setUniform(e.target.value)}
      />
      <Loader open={isLoading} />
      {/* <ToastContainer /> */}
    </div>
      <button  onClick={addAmount}>Add Amounts</button>
    </div>
  );
};

export default Essentialform;
