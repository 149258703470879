import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomTextField from "../TextField/TextFiels";
import useGetTeacherDetails from "../../hooks/useGetTeacherDetails";
import SelectField from "../selectTextField/selectTextfield";
import { genderArray } from "../../config";
import useTeacherSubjectIdQuery from "../../hooks/useGetTeacherMediumIds";
import MultiSelectField from "../MultiSelectField/MultiSelect";
import { DatePicker } from "@mui/x-date-pickers";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import useSubjectIQuery from "../../hooks/useGetSchoolSubjectId";
import { subjects, classes, mediums, allSections } from "../../config";
import useClassIdsQuery from "../../hooks/useGetSchoolIds";
import useMediumQuery from "../../hooks/useGetMediuIds";
import { classMapping, mediumMapping, subjectMapping } from "./helperfn";
import dayjs from "dayjs";

const EditTeacher = ({ selectedTeacher, onSubmit, onClose }) => {
  const {
    teacher_name,
    teacher_id,
    school_id,
    subject_id,
    mobile,
    age,
    gender,
    city,
    experience,
    email,
    date_of_joining,
    class_ids,
    qualification,
    salary,

    medium,
  } = selectedTeacher;
  const [teacherSubject, setTeacherSubject] = useState([]);
  const [teacherClass, setTeacherClass] = useState([]);
  const [selectedSubjects,setSelectedSubjects]=useState([]);

  // teaches details
  // const { teacherInfo, isLoading } = useGetTeacherDetails(teacher_id);
  // school subject details
  const { data: subjectIdData, isLoading: subjectIdLoading } =
    useSubjectIQuery(school_id);

  const { data: mediumIdData, isLoading: mediumIdLoading } =
    useMediumQuery(school_id);

  const { data: classIdData, isLoading: classIdLoading } =
    useClassIdsQuery(school_id);

  // teacher subject details
  // const { data: TeacherSubject, isLoading: teacherSubjectLoadig } =useTeacherSubjectIdQuery(teacher_id);

  // state for teacher data
  const [teacherData, setTeacherData] = useState({
    teacherName: "",
    age: "",
    mobile: "",
    email: "",
    city: "",
    experience: "",
    qualification: "",
    salary: "",
    gender: "",
    date: null,
    subjects: "",
    class_ids: "",
    medium_ids: "",
    sectiion: "",
  });
  // state of teache data error
  const [formErrors, setFormErrors] = useState({
    teacherName: false,
    age: false,
    mobile: false,
    email: false,
    city: false,
    experience: false,
    qualification: false,
    salary: false,
    gender: false,
    date: false,
    subjects: false,
    class_id: false,
    medium_ids: false,
  });

  // validation checker
  const validateField = (fieldName, value) => {
    if (fieldName === "teacherName" && value.trim() === "") {
      return "Teacher name is required";
    }

    return "";
  };

  // handle teacher data
  const handleFieldChange = (fieldName, value) => {
    setTeacherData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));

    // Validate field and update error state
    const error = validateField(fieldName, value);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error !== "",
    }));
  };
  const handleGender = (value) => {
    if (value.trim() === "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        ["gender"]: true,
      }));
      return;
    }
    setTeacherData((prevState) => ({
      ...prevState,
      ["gender"]: value,
    }));
  };
  const subjectListHandler = (subjectIds) => {
    setTeacherData((prevState) => ({
      ...prevState,
      ["subjects"]: subjectIds,
    }))
    setSelectedSubjects(subjectIds)
  };

  const classListHanlder = (class_ids) => {
    setTeacherData((prevState) => ({
      ...prevState,
      ["class_ids"]: class_ids,
    }));
  };
  const dateHandler = (date) => {
    setTeacherData((prevState) => ({
      ...prevState,
      ["date"]: date,
    }));
  };

  const handleSubmit = () => {
    onSubmit(teacherData);
  };
  useEffect(() => {
    setTeacherData({
      teacherName: teacher_name || "",
      age: age || "",
      mobile: mobile || "",
      email: email || "",
      city: city || "",
      experience: experience || "",
      qualification: qualification || "",
      salary: salary || "",
      gender: gender || "",
      date: dayjs(date_of_joining) || null,
      subjects: subject_id || [],
      class_ids: class_ids || [],
      medium: medium,
    });
    setTeacherSubject(subjectMapping(subject_id));

  }, [selectedTeacher]);

  return (
    <div style={{ padding: "4rem" }}>
      <Dialog
        open
        onClose={onClose}
        minWidth="80vw"
        fullWidth={true}
        PaperProps={{
          style: {
            minWidth: "1400px",
            width: "70vw", // Set the desired width
            // Set the desired height
            height: "auto",
            backgroundColor: "#F9FCFE",
          },
        }}
      >
        <DialogTitle>Edit Teacher</DialogTitle>
        {subjectIdLoading ? (
          <span>Loading ..</span>
        ) : (
          <DialogContent fullWidth>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                padding: "1rem 0.25rem",
              }}
            >
              <CustomTextField
                value={teacherData.teacherName}
                error={formErrors.teacherName}
                label="Teacher Name"
                onChange={(e) =>
                  handleFieldChange("teacherName", e.target.value)
                }
              />
              <CustomTextField
                value={teacherData.age}
                error={formErrors.age}
                label="Age"
                onChange={(e) => handleFieldChange("age", e.target.value)}
              />
              <CustomTextField
                value={teacherData.mobile}
                error={formErrors.mobile}
                label="Mobile"
                onChange={(e) => handleFieldChange("mobile", e.target.value)}
              />
              <CustomTextField
                value={teacherData.email}
                error={formErrors.email}
                label="Email"
                onChange={(e) => handleFieldChange("email", e.target.value)}
              />
              <CustomTextField
                value={teacherData.experience}
                error={formErrors.experience}
                label="Experience"
                onChange={(e) =>
                  handleFieldChange("experience", e.target.value)
                }
              />
              <CustomTextField
                value={teacherData.qualification}
                error={formErrors.qualification}
                label="Qualification"
                onChange={(e) =>
                  handleFieldChange("qualification", e.target.value)
                }
              />
              <CustomTextField
                value={teacherData.city}
                error={formErrors.city}
                label="City"
                onChange={(e) => handleFieldChange("city", e.target.value)}
              />
              <CustomTextField
                value={teacherData.salary}
                error={formErrors.salary}
                label="Salary"
                onChange={(e) => handleFieldChange("salary", e.target.value)}
              />
              <SelectField
                label="Gender"
                items={genderArray}
                onChange={handleGender}
                idKey="value"
                nameKey="label"
                dfValue={teacherData.gender}
              />
              <MultiSelectField
                label="Subject"
                items={subjectIdData}
                loading={subjectIdLoading}
                onChange={subjectListHandler}
                idKey="subject_id"
                nameKey="subject_name"
                initialSelectedItems={teacherSubject}
                selectedItems={selectedSubjects}
                setSelectedItems={setSelectedSubjects}
              
              />
              {/* <MultiSelectField
                label="Class"
                items={classIdData}
                loading={classIdLoading}
                onChange={classListHanlder}
                idKey="class_id"
                nameKey="class_name"
                initialSelectedItems={teacherClass}
              /> */}

              <DatePicker
              label="Joining Date"
                value={teacherData.date}
                sx={{ flex: 1 }}
                error={formErrors.date}
                format="DD/MM/YYYY"
                type="date"
                onChange={dateHandler}
                required
              />
            </div>
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditTeacher;
